import Bots from "../components/pages/Bots";
import Services from "../components/pages/Services";
import Home from "../components/pages/Home";
import Minting from "../components/pages/Minting";
import Web2 from "../components/pages/Web2";

export const pages = [
    {
        path: '/',
        element: <Services />,
        navTitle: ''
    },
    {
        link: 'https://wasabimusic.io/',
        navTitle: 'Our Portfolio'
    },
    {
        link: 'https://twitter.com/daboigbae',
        navTitle: 'Twitter'
    },
    {
        link: 'https://calendly.com/2brothers/30min',
        navTitle: 'Contact Us'
    },
]