import React from 'react'
import styled from 'styled-components'
import { dappGridData } from '../../data/dappGridData'
import { device } from '../styles/breakpoints'

const Wrapper = styled.div`
    height: 60vh;
    width: 100%;

    display: grid;
    grid-template-columns: 50% 50%;

    @media ${device.tablet}{
        grid-template-columns: 100%;
        height: auto;
        width: 90%;
    }
`


const GridTitle = styled.p` 
    margin-top: 12px;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .2rem;
    color: white;
    transition: .3s ease-in-out;
`

const GridBody = styled.p`
    font-size: 16px;
    font-weight: 300;
    letter-spacing: .1rem;
    color: white;
    text-align: center;
    width: 80%;
    margin-top: 8px;
    transition: .3s ease-in-out;
`
const GridEmoji = styled.p`
    font-size: 32px;
    font-weight: 300;
    letter-spacing: .1rem;
    color: white;
    text-align: center;
    width: 80%;
    margin-top: 8px;
    transition: .3s ease-in-out;
`

const GridBox = styled.div`
    height: 30vh;
    border: 1px solid white;
    cursor: pointer;

    transition: .3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover{
        border: none;
        background-color: #d819a2;
        border-radius: 8px;
        transform: scale(1.1);
        z-index:99999;
    }

    &:hover ${GridBody}{
        color: white;
    }
    &:hover ${GridTitle}{
        color: white;
    }

    @media ${device.tablet}{
        margin-bottom: 4vh;
        height: 40vh;
    }
`


export default function GridDisplayComponent() {
    return (
        <Wrapper>
            {dappGridData.map((item, index) => {
                return (
                    <GridBox key={index}>
                        <GridEmoji>{item.icon}</GridEmoji>
                        <GridTitle>{item.title}</GridTitle>
                        <GridBody>{item.body}</GridBody>
                    </GridBox>
                )
            })}



        </Wrapper>
    )
}
