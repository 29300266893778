
import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { pages } from '../../data/pages'
import { AnimatePresence } from 'framer-motion'
import Navbar from '../navigation/Navbar'


export default function Routing() {

    const location = useLocation()

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    {pages.map((page, index) => {
                        return (
                            <Route key={index} path={page.path} element={page.element} />
                        )
                    })}
                </Routes>
            </AnimatePresence>
        </>
    )
}
