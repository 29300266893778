import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppContextProvider from './components/context/AppContextProvider';
import { GlobalStyles } from './components/styles/GlobalStyles';
import Routing from './components/Routing/Routing';


function App() {
    return (
        <AppContextProvider>
            <BrowserRouter>
                <GlobalStyles />
                <Routing />
            </BrowserRouter>
        </AppContextProvider>
    );
}

export default App;
