export const dappGridData = [
    {
        title: 'Mobile',
        icon: '📱',
        body: 'Through react native, we are able to build almost any mobile app from scratch faster than our competitors.'
    },
    {
        title: 'NFT Mint Sites',
        icon: '🖼️',
        body: 'From contract to mint site, we have everything you need to get sell out your NFT collection.'
    },
    {
        title: 'DAPPs & Web3',
        icon: '💻',
        body: 'Let us build a token-gated experience your community will love. Custom made or out of the box, we got ideas!'
    },
    {
        title: 'Consulting',
        icon: '👨‍💻',
        body: "Are your developers building things to help your business? Or are they building things that are cool? We'll let you know."
    },


]