import React, { createContext } from 'react'
import { loadFull } from 'tsparticles';

export const AppContext = createContext()

export default function AppContextProvider({ children }) {

    const particlesInit = async (main) => {
        loadFull(main)
    };

    return (
        <AppContext.Provider
            value={{
                particlesInit
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
