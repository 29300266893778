import { createGlobalStyle } from "styled-components";


export const GlobalStyles = createGlobalStyle`

    * {
        border: 0;
        box-sizing: border-box;
        color: inherit;
        font-size: inherit;
        margin: 0;
        padding: 0;
    }

    html, body, #root {
        height: 100%;
        background-color: white;
        font-family: 'Atkinson Hyperlegible', sans-serif;
        color: white;
    }


    a {
        font-weight: 500;
        text-decoration: none;
    }
`;