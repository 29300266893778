import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { pages } from '../../data/pages'
import { device } from '../styles/breakpoints';

const MenuLink = styled(Link)`
  padding: 1rem 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in;
  font-size: 1rem;
  &:hover {
    color: #7b7fda;
  }
`;

const ExternalLink = styled.a`
padding: 1rem 2rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in;
  font-size: 1rem;
  &:hover {
    color: #7b7fda;
  }
`
const Nav = styled.div`
  padding: 0 1rem;
  display: flex;
  z-index: 99999;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media ${device.tablet}{
    background-color: #1e002f !important;
    opacity: .7;
  }
`;

const Logo = styled(Link)`
  padding: 1rem 0;
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-size: 2rem;
  span {
    font-weight: 300;
    font-size: 1.5rem;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    overflow: hidden;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
    transition: max-height 0.3s ease-in;
    width: 100%;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 2px;
    width: 25px;
    background: #7b7fda;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export default function Navbar() {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Nav>
            <Logo to="/">
                2<span>Brothers</span>
            </Logo>
            <Hamburger onClick={() => setIsOpen(!isOpen)}>
                <span />
                <span />
                <span />
            </Hamburger>
            <Menu isOpen={isOpen}>
              {pages.map((page, index) => {
                return !page?.link
                  ? <MenuLink
                      key={index}
                      to={page.path}> {page.navTitle}</MenuLink> 
                  : <ExternalLink href={page?.link} target="_blank" >{page.navTitle} </ExternalLink >
                  })}
            </Menu>
        </Nav>
    )
}
