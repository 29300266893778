import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import deadfellaz from '../../assets/images/deadfellaz.png'
import train from '../../assets/images/train.webp'
import { gbaeSocials, trainSocials } from '../../data/Socials'
import { device } from '../styles/breakpoints'
import Navbar from '../navigation/Navbar'

const Wrapper = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
 

    @media ${device.laptop} {
    flex-direction: row;
    
    };

    @media ${device.tablet}{
        flex-direction: column;
    };


    
`

const BackgroundImage = styled.img`
    height: 100%;

`

const ContentContainer = styled(motion.div)`
    height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50vw;

    @media ${device.laptop}{
        width: 50vw;
    };

    @media ${device.tablet} {
        width: 100%;
    };
`

const ContentAvatar = styled.img`
    height: 8vh;
    width:  8vh;
    border-radius: 100%;
`

const ContentTitle = styled.p`
    margin:0;
    font-size: 88px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .3rem;
    font-family: 'Permanent Marker', cursive;

    @media ${device.tablet} {
        font-size: 64px;
    }
`

const ContentBody = styled.p`
    text-align: center;
    width: 35vw;
    margin:0;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .3rem;

    @media ${device.tablet} {
        font-size: 16px;
        width: 90%;
    }
`

const SocialsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    margin-top: 3vh;
    width: 30vw;

    @media ${device.tablet} {
        width: 90vw;
    }
`


const SocialCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 7vh;
    margin-left: 1vh;
    margin-right: 1vh;
    cursor: pointer;
    border-radius: 100%;
    border: 6px solid white;
    transition: .1s ease-in-out;

    &:hover {
        background: white;
    }

    @media ${device.tablet} {
        height: 5vh;
        width: 5vh;
    }
`

const ServiceButton = styled.div`
    position: absolute;
    height: 5vh;
    width: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid ${({ bg }) => bg};
    color: ${({ bg }) => bg};
    bottom: 15px;
    right: ${({ right }) => right};
    left: ${({ left }) => left};
    letter-spacing: .4rem;
    font-weight: 300;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 4px;
    transition: .3s ease-in-out;

    &:hover {
        background: ${({ bg }) => bg};
        color: white;
        font-weight: 700
    }

    @media ${device.tablet}{
        width: 40vw;
        background: ${({ bg }) => bg};
        color: white;
        font-size: 12px;
        font-weight: 700;
    }
`


export default function Home() {
    return (
        <Wrapper>
            <Navbar />
            <ContentContainer src={deadfellaz}
                initial={{ x: -window.outerWidth }}
                animate={{ x: 0, transition: { duration: .5 } }}
                exit={{ x: -window.outerWidth, transition: { duration: .5 } }}
            >
                <ContentTitle>Gbae</ContentTitle>
                <ContentBody>Mobile Developer</ContentBody>
                <SocialsContainer>
                    {gbaeSocials.map((icon) => {
                        return (
                            <SocialCircle>

                            </SocialCircle>
                        )
                    })}
                </SocialsContainer>
                <ServiceButton bg="#A10035" left="25px">Stixagram</ServiceButton>
            </ContentContainer>

            <ContentContainer src={train}
                initial={{ x: window.innerWidth }}
                animate={{ x: 0, transition: { duration: .5 } }}
                exit={{ x: window.innerWidth, transition: { duration: .5 } }}
            >
                <ContentTitle>Train</ContentTitle>
                <ContentBody>Web3 Developer</ContentBody>
                <SocialsContainer>
                    {trainSocials.map((icon) => {
                        return (
                            <SocialCircle />
                        )
                    })}
                </SocialsContainer>
                <ServiceButton bg='#47B5FF' right="25px">Wasabi</ServiceButton>
            </ContentContainer>

        </Wrapper>
    )
}
