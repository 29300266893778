import { motion } from 'framer-motion'
import React, { useContext } from 'react'
import Particles from 'react-tsparticles'
import styled from 'styled-components'
import { AppContext } from '../context/AppContextProvider'

import particlesConfig from '../../data/particlesConfig.json'
import GridDisplayComponent from '../Grid/GridDisplayComponent'
import { device } from '../styles/breakpoints'
import Navbar from '../navigation/Navbar'

const Wrapper = styled(motion.div)`
    height: 100vh;
    width: 100vw;
    background-color: #1e002f;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media ${device.tablet}{
        height: auto !important;
        flex-direction: column;
    }
`

const ContentWrapper = styled(motion.div)`
z-index: 9999;
    height: 100%;
    width: ${({ width }) => width};
    background-color: ${({ color }) => color};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media ${device.tablet} {
        margin-top: 15vh;
        align-items: center;
        height: auto !important;
        width: 100%;
        text-align: center !important;

    }
`

const ContentTitle = styled.p`
    font-size: 58px;
    letter-spacing: .5rem;
    font-weight: 900;
    text-transform: uppercase;
    margin:0; 
    margin-top: 12px;

    @media ${device.tablet}{
        font-size: 28px;
    }
`

const ContentSub = styled.p`
    font-size: 24px;
    letter-spacing: .5rem;
    font-weight: 300;
    text-transform: lowercase;
    margin: 0;

    @media ${device.tablet}{
        font-size: 18px;
    }

`

const ContentBody = styled.p`
    font-size: 20px;
    width: 90%;
    lettter-spacing: .1rem;
    font-weight: 300;
    line-height: 2rem;
    margin: 0;
    margin-top: 18px;
    
`

const ScheduleButton = styled.button`

    background-color: #d819a2;
`

export default function Minting() {

    const { particlesInit } = useContext(AppContext)

    return (
        <Wrapper initial={{ height: 0 }} animate={{ height: '100%', transition: { duration: .3 } }} exit={{ y: window.innerHeight, transition: { duration: .3 } }} >
            <Navbar />
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={particlesConfig}
            />
            <ContentWrapper initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: .3, duration: .3 } }} exit={{ opacity: 0, transition: { duration: .2 } }} width="30vw">
                <ContentSub>we can help with</ContentSub>
                <ContentTitle>Minting Services  </ContentTitle>
                <ContentBody>
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking
                    at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters
                </ContentBody>
                {/* <ScheduleButton>I want to build a Decentralized Application</ScheduleButton> */}
            </ContentWrapper>
            <ContentWrapper initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: .3, duration: .4 } }} exit={{ opacity: 0, transition: { duration: .2 } }} width="45vw">
                <GridDisplayComponent />
            </ContentWrapper>
        </Wrapper>
    )
}
